import './App.css';
import logo from "./img/logo.png"
function App() {
  return (
    <div className="App">
      <div className="left">
        <img src={logo} className="logo" alt="qwrtx logo"/>
        <div className="blurb">
          <div className="subtitle">
            HOME OF WEB3 INSPIRATION
          </div>
          <div className="title">
            Studio for adaptive and efficient decentralized app.
          </div>
          <div className="contact">
            Let's hear about you and your projects at <a href="mailTo:contact@qwrtx.com">contact@qwrtx.com</a>
          </div>
        </div>
      </div>
      <div className="right">
        Q
      </div>
    </div>
  );
}
export default App;